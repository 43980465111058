import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import CF7Newsletter from '../../../../Form/CF7/Newsletter'
import Intro from '../../../../../animations/Intro'

function CustomBlock({ fields: field }) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	return (
        <div ref={io}>
            <Intro visible={ioInView} in={{ fade: 500 }} delayIn={100} mounted={true} stay={true} className={``}>
                <CF7Newsletter />
            </Intro>
        </div>
	)
}

export default CustomBlock