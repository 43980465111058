import React from 'react'
import './style.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from "gatsby"

import logo from '../../images/logo-dark.png'

import { useInView } from 'react-intersection-observer'
import Intro from '../../animations/Intro'

function Footer(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	const baseDelay = 200
	const stepDelay = 100

	// Get footer Image from WP Site Settings
	const data = useStaticQuery(graphql`
		query {
    	...acfOptions
  	}
	`)
	const image = data?.wp?.options?.acfOptions?.footerImage?.localFile?.childImageSharp.gatsbyImageData

  return (
		<footer ref={io} className='master-footer c0 grid-12 padd'>
			
			<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay} mounted={true} stay={true} className={``}>
				<div className='social-footer content-box'>
					<p><a href='https://facebook.com/bh3' className='hint--rounded hint--top' aria-label="Facebook Link" data-hint='@bh3' target='_blank' rel='noopener noreferrer'>Facebook</a><br/>
					<a href='https://instagram.com/bh3' className='hint--rounded hint--top' aria-label="Instagram Link" data-hint='@bh3' target='_blank' rel='noopener noreferrer'>Instagram</a><br/>
					<a href='https://linkdin.com/bh3' className='hint--rounded hint--top' aria-label="Linkdin Link" data-hint='@bh3' target='_blank' rel='noopener noreferrer'>Linkdin</a></p>
				</div>
			</Intro>
				
			<div className="details span-6 span-12-tablet grid-12 gap-1">
				<div className="span-12 padd-top-2 padd-bottom-2 grid-12">

					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 1} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p>Florida<br/>
							819 NE 2nd Avenue<br/>
							Suite 500<br/>
							Fort Lauderdale, FL 33304<br/>
						</p>
						<p>
						<a href="tel:9544163140">Phone: 954-416-3140</a><br/>
						Fax: 954-603-3432</p>
					</Intro>
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 2} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p>New York<br/>
							475 Park Avenue South<br/>
							Suite 1206<br/>
							New York, NY 10016<br/>
						</p>
						<p>
							<a href="tel:6462898688">Phone: 646-289-8688</a><br/>
							Fax: 954-603-3432</p>
					</Intro>
				</div>
				<div className="span-12 padd-bottom grid-12">
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 3} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p>Work with us</p>
					</Intro>
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 4} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p><a href="mailto:hello@bh3.com">hello@bh3.com</a></p>
					</Intro>
				</div>
				<div className="span-12 padd-bottom grid-12">
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 5} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p>Work for us</p>
					</Intro>
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 6} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p><a href="mailto:wrk@bh3.com">wrk@bh3.com</a></p>
					</Intro>
				</div>
				<div className="span-12 padd-bottom grid-12">
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 7} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p>Write about us</p>
					</Intro>
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 8} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p><a href="mailto:press@bh3.com">press@bh3.com</a></p>
					</Intro>
				</div>
				<div className="span-12 padd-top padd-bottom-2 grid-12">
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 9} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p><a href="#">Privacy Policy</a></p>
					</Intro>
					<Intro visible={ioInView} in={{ fade: 500 }} delayIn={baseDelay + stepDelay * 10} mounted={true} stay={true} className={`content-box span-6 span-12-tablet`}>
						<p><a href="https://www.deepsleepstudio.com" target="_blank" rel="noreferrer noopener">Site By DeepSleep Studio</a></p>
					</Intro>
				</div>
			</div>
			
			<div className='copy c0'>
				<span>Copyright &copy; 2021. BH3</span>
				<a href="#" className="link">Privacy Policy</a>
				<a href="#" className="link">Terms of Service</a>
				<a href="#" className="link">Disclaimer</a>
			</div>
				
			<div className="footdetail">
				<div className="line"></div>
				<div className="logo">
					<div className="aspect-ratio">
						<img src={logo} alt="BH3 Logo" />
					</div>
				</div>
			</div>
				
			<Intro visible={ioInView} in={{ blink: 500 }} delayIn={baseDelay} mounted={true} stay={true} className={`pattern-box-wrapper`}>
				<div className="pattern-box"></div>
			</Intro>
				
		</footer>
  )
}

export default Footer